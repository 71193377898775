.modalContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  max-width: 60%;
  max-height: 60%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.modalStyle {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 500px;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.modalContainer h1 {
  border-bottom: 1px solid #cccccc;
  font-size: 2rem;
  padding-bottom: 1rem;
}

.contentContainer {
  padding: 1rem 0;
  overflow: auto;
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #cccccc;
  padding-top: 5px;
}

.footer button {
  margin: 8px;
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}
