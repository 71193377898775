.pageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  width: 100%;
  padding-bottom: 20px;
}

.pageSubHeader {
  display: 'inline-block';
  float: 'left';
  padding-top: 12px;
  padding-bottom: 12px;
}

.mainHeader {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.tableTopHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.title {
  font-weight: bolder;
}

.subTitle {
  font-weight: 600;
}

.subMain {
  padding-top: 25px;
}

.emptyMain {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.emptySub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10;
  margin-inline: 25%;
  padding-block: 20;
}
