.tooltip {
  position: absolute;
  pointer-events: none;
  background: #fff;
  color: #335e79;
  border: 1px solid;
  border-radius: 5px;
  border-color: #d5af71;
  border-width: 2px;
  min-height: 45px;
  font-weight: bold;
  padding: 10px;
}

.prodtext {
  font-weight: bold;
}

.pq {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pqmain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pqtext {
  padding-right: 5px;
}

.pqcircle {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  max-height: 12px;
  max-width: 12px;
}

.productLink {
  color: black;
}

.d3-context-menu {
  position: absolute;
  display: none;
  background-color: #f2f2f2;
  border-radius: 4px;

  font-family: Arial, sans-serif;
  font-size: 14px;
  min-width: 150px;
  border: 1px solid #d4d4d4;

  z-index: 1200;
}

.d3-context-menu ul {
  list-style-type: none;
  margin: 4px 0px;
  padding: 0px;
  cursor: default;
}

.d3-context-menu ul li {
  padding: 4px 16px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.d3-context-menu ul li:hover {
  background-color: #4677f8;
  color: #fefefe;
}

/*
	Header
*/

.d3-context-menu ul li.is-header,
.d3-context-menu ul li.is-header:hover {
  background-color: #f2f2f2;
  color: #444;
  font-weight: bold;
  font-style: italic;
}

/*
	Disabled
*/

.d3-context-menu ul li.is-disabled,
.d3-context-menu ul li.is-disabled:hover {
  background-color: #f2f2f2;
  color: #888;
  cursor: not-allowed;
}

/*
	Divider
*/

.d3-context-menu ul li.is-divider {
  padding: 0px 0px;
}

.d3-context-menu ul li.is-divider:hover {
  background-color: #f2f2f2;
}

.d3-context-menu ul hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.cust-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.cust-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40% !important;
  height: 20%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
