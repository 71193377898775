#projectmap-tooltip {
  position: absolute;
  pointer-events: none;
  background: #fff;
  color: #335e79;
  border: 1px solid;
  border-radius: 5px;
  border-color: #d5af71;
  border-width: 2px;
  min-height: 45px;
  font-weight: bold;
  padding: 10px;
}
