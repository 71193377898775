.tableBox {
  border: 2px solid;
}

.tableFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-inline: 10px;
}

.tableContainer {
  border: 2px solid;
  padding: 20px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  border-top: 2px solid;
  padding-bottom: 10px;
}

.buttonSubGroupLeft {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80%;
  margin-right: 28%;
}

.buttonSubGroupRight {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  width: 20%;
}

.paginationGroup {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  align-items: flex-end;
  justify-content: space-between;
}

.tableFooterButtonGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 10px;
}

.languageSelectionGroup {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 10px;
  align-items: flex-end;
  justify-content: flex-end;
}
