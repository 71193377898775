.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specialFields {
  display: flex;
  justify-content: space-between;
}

/* Selected product list */
.selectedProductList {
  list-style: none;
  padding-left: 0;
  /* border: 1px solid rgb(151,151,151); */
}

.selectedProductListElement {
  padding: 1rem;
  border-bottom: 1px solid rgb(151, 151, 151);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectedProductListElement:last-of-type {
  border-bottom: none;
}

.productNotFoundText {
  color: #e46e6e;
}

.loadMoreProductsBtn {
  margin: 4% auto !important;
}

.loadMoreProductsSection {
  margin: 4% auto !important;
  text-align: center !important;
}

/* Autocomplete */
.autoCompleteList {
  background-color: rgb(244, 244, 244);
  list-style: none;
  padding: 0;
  width: 500px;
  margin-top: -1rem;
  margin-left: -10px;
  /* margin-top: 8rem; */
  /* position: absolute; */
  z-index: 2;
  max-height: 400px;
  overflow: auto;
}

.autoCompleteListElement {
  border-bottom: 1px solid rgb(151, 151, 151);
  color: rgb(74, 74, 74);
  height: 35px;

  display: flex;
  align-items: center;

  padding-left: 1rem;
}

.autoCompleteListElement:hover {
  background-color: rgba(74, 144, 226, 0.39);
  cursor: pointer;
}

.selectedAutoCompleteListElement {
  background-color: rgba(74, 144, 226, 0.39);
}

.autoCompleteListElement:last-of-type {
  border-bottom: none;
}

.removeBtn {
  border: none;
  outline: none;
}

.removeBtn:hover {
  cursor: pointer;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.submitBtn {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error {
  display: block;
  color: #ff0000;
  font-weight: bold;
  margin-top: 1rem;
}

.blindLabelInput {
  width: 100%;
  border: 0;
  border-bottom: 1px solid;
}

.card {
  margin: 10px auto !important;
}

.leftCard {
  width: 40%;
  height: 440px;
  display: inline-block;
  margin-right: 2%;
}
.rightCard {
  width: 58%;
  height: 440px;
  display: inline-block;
}

@media not all and (min-resolution: 0.001dpcm) {
  .drag {
    width: 20px !important;
    height: 20px !important;
    cursor: move;
  }
}

.drag {
  width: 10px;
  height: 10px;
  cursor: move;
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}
