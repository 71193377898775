.container {
  display: flex;
  flex-direction: column;
  width: 98%;
  max-width: 96%;
  padding: 4.2rem 3.2rem;
}

.header {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.backIcon {
  margin-left: -26px;
  padding-bottom: 20px;
  color: var(--marine);
}

.backText {
  color: var(--marine);
  padding-left: 5px;
}

.radarContainer {
  margin-top: 30px;
}

.radarTitle {
  text-align: center;
  padding-bottom: 10px;
}

.productMarketPreferenceContainer {
  margin-top: 30px;
  justify-content: center;
}

.productMarketPreferenceContainer h5 {
  text-align: center;
}

.pqScoreWinRate {
  margin: auto;
}
