.container {
  width: 95%;
  max-width: 80%;
  padding: 4.2rem 3.2rem;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.actionColumn {
  width: 70px;
}

.submitterColumn {
  width: 190px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.notificationHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.notificationTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 24px;
  color: var(--marine);
}
