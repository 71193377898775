.deviceCreate {
  width: 95%;
  max-width: 80%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.paperContainer {
  padding: 1.2rem 3.2rem;
}

.paperContainer input {
  width: 300px;
}
