.mainContainer {
  display: flex;
  flex-direction: row;
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fieldContainer {
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  width: 550px;
  padding-inline: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header {
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.footer button {
  margin: 8px;
}

.error {
  color: red;
  font-size: 12px;
}
