.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}
