.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.productHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.productTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 24px;
  color: var(--marine);
}

.container {
  width: 98%;
  max-width: 96%;
  padding: 4.2rem 3.2rem;
  position: relative;
}

.productTable {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 100px;
}

.productTable ul {
  align-items: flex-end;
  justify-content: flex-end;
}

.productTable input {
  border-radius: 16px;
  background-color: rgb(244, 244, 244);
  outline: none;
  border: none;
  height: 37px;
  width: 250px;
  padding-left: 2.5rem;
  margin: 1rem 0 1rem -1rem;
}

.panelColumn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.image {
  width: 72px !important;
  height: 72px !important;
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.tableHeader > th[data-row-selection] {
  width: 50px !important;
}

.smallHeader {
  width: 100px;
}

.arrowExpand {
  border-radius: 10% !important;
  background-color: rgba(2, 41, 80, 0.08) !important;
}

.arrowExpand:hover {
  background-color: var(--aqua-marine) !important;
}

.arrowLess {
  border-radius: 10% !important;
  background-color: var(--aqua-marine) !important;
}

.expandTable {
  padding: 0 !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  min-width: 30%;
  max-width: 60%;
  max-height: 60%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
