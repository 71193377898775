.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5px 5px;
}

.label {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  color: var(--marine);
  font-size: 14px;
}

.fields {
}
