.formContainer {
  padding: 1.2rem 3.2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 80%;
}

.productClassAttributes {
  display: flex;
  flex-direction: column;
}

.label {
  width: 15rem;
}

.productFieldsTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 24px;
  color: var(--marine);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.buttonContainer button {
  margin-left: 15px;
}

.sectionContainer,
.productClassAttributes {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dateContainer,
.productClassAttributes {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
}

.hideTrue {
  display: none;
}

.hideFalse {
}

.additionalInfoContainer {
  display: flex;
  flex-direction: column;
  margin-right: 50%;
  align-items: flex-end;
  margin-top: 5px;
}

.additionalFieldContainer {
  width: 100%;
}

.definedContainer {
  flex: 1;
  width: 100%;
}

.definedContainerName {
  flex: 1;
  margin-left: 25px;
  margin-right: 25px;
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 14px;
  color: var(--marine);
}

.undefinedContainer {
  margin: 0 5px;
  flex: 1;
  width: 50%;
  margin-left: 50%;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  flex: 4;
  width: 50%;
  margin-top: 5px;
  margin-left: 25px;
}
