.buttonContainer {
  margin-bottom: 15px;
}

.sectionContainer {
  padding-bottom: 10px;
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  min-width: 60%;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modalButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.modalButtonContainer button {
  margin-left: 1em;
}

.warningButton {
  color: red;
  width: 90px;
  height: 90px;
}

.iconContainer {
  flex: 1;
  align-self: center;
  padding-bottom: 10px;
}

.cancelIcon {
  color: red;
}