.alertBox {
  padding: 1rem;
  margin: 2rem 1rem;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.success {
  background-color: #00b263;
}

.error {
  background-color: #ff3919;
}
