.container {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  color: var(--marine);
  font-size: 14px;
  padding-top: 30px;
}

.containerProduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
