.container {
  width: 95%;
  max-width: 80%;
  padding: 4.2rem 3.2rem;
}

.sectionContainer {
  padding-bottom: 10px;
}

.inputSelect {
  padding-top: 18px;
}

.notFoundTable > tr > td {
  padding: 5px;
  border: 1px solid black;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}
