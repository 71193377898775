.container {
  width: 95%;
  max-width: 80%;
  padding: 4.2rem 3.2rem;
}

.sectionContainer {
  padding-bottom: 10px;
}

.addUserRoleFormParent {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  min-width: 500px;
  justify-content: space-between;
  align-items: center;
}

.addUserRoleForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
