.container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.field {
  flex: 4;
}

.rowContainer {
  margin-top: 10px;
  flex: 1;
}
