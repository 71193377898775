.ternaryWithPicker {
  min-height: '475px';
}

.selectView {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  min-width: 20rem;
  padding-block: 2rem;
}

.select {
  width: 80%;
  padding-right: 5px;
}
