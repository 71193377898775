.container {
  display: flex;
  flex-direction: column;
  padding: 4.2rem 3.2rem;
}

.selectMain {
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  padding: 5px;
  margin: 5px;
}

.selectViewContainer {
  width: 50%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  margin: 5px;
}

.header {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.summaryTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 20px;
  color: var(--marine);
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.profileContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: auto;
  justify-content: space-between;
  align-items: stretch;
}
