.container {
  width: 95%;
  max-width: 80%;
  padding: 4.2rem 3.2rem;
}

.header {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 24px;
  color: var(--marine);
}
