.buttonContainer {
  margin-bottom: 15px;
}

.sectionContainer {
  padding-bottom: 10px;
}

.list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px;
}
