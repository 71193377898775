.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  
}

.sectionContainer {
 
}

.dialogHeader {
  display: flex;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.buttonContainer button {
  margin-left: 1em;
}
