.container {
  display: flex;
  flex-direction: column;
  width: 98%;
  max-width: 96%;
  padding: 4.2rem 3.2rem;
}

.subContainer {
  display: flex;
  flex-direction: row;
}

.selectView {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  min-width: 20rem;
  padding-block: 2rem;
}

.selectProductView {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  min-width: 20rem;
  padding-block: '0em 2em 1em';
}

.select {
  width: 100%;
  padding-right: 5px;
}

.icon {
  display: flex;
  flex-direction: column;
  margin-block: 2px;
  justify-content: flex-end;
}

.header {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.summaryHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 10px;
  color: var(--aqua-marine);
}

.summaryTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 20px;
  color: var(--marine);
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.compareButton {
  padding-top: 10px;
  padding-right: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.tabContainer {
  padding-top: 2rem;
}

.impactView {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  flex-direction: 'column';
  height: '100%';
}

.impactItem {
  flex: 1;
  margin-top: '30px';
}
