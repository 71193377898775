.container {
  width: 98%;
  max-width: 96%;
  padding: 4.2rem 3.2rem;
  position: relative;
}

.tableContainer {
  border-collapse: collapse;
  width: 100%;
}

.tableContainer tr {
  width: 100%;
}

.tableContainer th {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 11px;
  color: var(--marine);
  padding: 8px 10px;
  line-height: 15pt;
  background-color: #19394f;
  color: white;
}

.tableContainer td {
  padding: 18px 20px;
  color: var(--marine);
  font-family: OpenSans, sans-serif;
  font-size: 11px;
  line-height: 18px;
}

.tableContainer tr:nth-child(odd) {
  background-color: var(--pale-grey);
}

.tableFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-inline: 10px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  border-top: 2px solid;
  padding-bottom: 10px;
}

.buttonSubGroupLeft {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80%;
  margin-right: 28%;
}

.buttonSubGroupRight {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  width: 20%;
}

.paginationGroup {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  align-items: flex-end;
  justify-content: space-between;
}

.tableFooterButtonGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 10px;
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}
