.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  min-width: 60%;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  overflow: scroll;
}

.sectionContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.buttonContainer button {
  margin-left: 1em;
}
