.box {
  text-align: center;
  border: 2px solid;
}

.desc {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 18px;
  color: var(--marine);
}

.ggVar {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 36px;
  color: var(--marine);
}

.ggVarNone {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 36px;
  color: transparent;
}
