.container {
  width: 95%;
  max-width: 80%;
  padding: 4.2rem 3.2rem;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}
