.datePicker {
  outline: none;
  border: 1px solid rgb(151, 151, 151);
  padding-left: 1rem;
  height: 37px;
  /* border-radius: 16px; */
  margin-bottom: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.timeContainer {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.timePicker {
  display: flex;
  flex-direction: row;
}

input[type='date']::-webkit-clear-button {
  display: none;
}
