@import '~sanitize.css';
@import '~graphiql/graphiql.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~filepond/dist/filepond.css';
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

#root {
  align-items: stretch;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  --marine: #022950;
  --marine-faded: rgba(2, 41, 80, 0.4);
  --aqua-marine: #3ad6cc;
  --coral-pink: #e46e6e;
  --saffron: #eac435;
  --white-faded: rgba(255, 255, 255, 0.4);
  --pale-grey: #f4f6f9;
}

#root * {
  outline: none;
}

@font-face {
  font-family: 'OpenSans';
  src:
    local('OpenSans'),
    url(./styles/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  src:
    local('OpenSans-Bold'),
    url(./styles/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'AlphaHeadlinePro';
  src:
    local('AlphaHeadlinePro'),
    url(./styles/fonts/AlphaHeadlinePro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'AlphaHeadlinePro-Bold';
  src:
    local('AlphaHeadlinePro-Bold'),
    url(./styles/fonts/AlphaHeadlinePro-Bold.otf) format('opentype');
}

svg {
  fill: context-fill;
}

a {
  text-decoration: none !important;
}

*::placeholder {
  color: var(--marine-faded);
  font-family: OpenSans, sans-serif;
}

.page-item.active .page-link {
  background-color: var(--aqua-marine);
  border-color: var(--aqua-marine);
}
.page-link {
  background-color: var(--pale-grey);
  color: var(--marine);
  font-family: AlphaHeadlinePro-Bold, sans-serif;
}
.btn-secondary.dropdown-toggle {
  color: var(--marine);
  background-color: var(--pale-grey);
  border: none;
}
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--aqua-marine);
  border: none;
}
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.Select-menu-outer {
  z-index: 999 !important;
}

.filepond--file-action-button {
  cursor: pointer;
}

.filepond--drop-label {
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  color: var(--marine-faded);
}

.filepond--panel-root {
  border-style: dotted;
  border-width: 2px;
  border-radius: 0;
  border-color: var(--marine-faded);
  background-color: white;
}
