.addUserRoleForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.addUserRoleFormParent {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  min-width: 500px;
  justify-content: space-between;
  align-items: center;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
}
