.container {
  width: 98%;
  max-width: 96%;
  padding: 4.2rem 3.2rem;
  position: relative;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.projectHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.projectTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 24px;
  color: var(--marine);
}
