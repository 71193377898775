.container {
  display: flex;
  flex-direction: column;
  width: 98%;
  max-width: 96%;
  padding: 4.2rem 3.2rem;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.projectHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--aqua-marine);
}

.projectTitle {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 24px;
  color: var(--marine);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px;
  border-top: var(--pale-grey) solid 1px;
}

.tableRow {
  font-size: 12px;
  font-family: OpenSans, sans-serif;
  color: var(--marine);
  word-wrap: break-word;
}

.tableHeader {
  font-family: AlphaHeadlinePro-Bold, sans-serif;
  font-size: 12px;
  color: var(--marine);
}

.tableHeader > th[data-row-selection] {
  width: 50px !important;
}
